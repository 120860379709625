import React from "react";
import lodash from "lodash";
import { graphql } from "gatsby";
import BayEquityNews from "../../components/BayEquityNews";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import { mapBlogs } from "../../lib/utility";
import { Helmet } from "react-helmet";

const BayEquityNewsPage = ({ data }) => {
    const { corpBlogs, featBlogs } = data;
    const { edges: corpBlopPosts = [] } = corpBlogs || {};
    let corpBlobPosts = corpBlopPosts;
    const { edges: featBlobPosts = [] } = featBlogs || {};
    corpBlobPosts.push(...featBlobPosts); //featured blogs
    corpBlobPosts.sort((a, b) => {
        return new Date(b.node.date) - new Date(a.node.date);
    });
    let corporateBlogs = mapBlogs(corpBlobPosts);
    corporateBlogs = lodash.uniqBy(corporateBlogs, "id");
    let props = {
        corporateBlogs,
    };

    const header = (
        <Header
            active={["tools-and-resources", "bay-equity-news"]}
            theme="blue-ribbon"
        />
    );

    return (
        <Layout hasHero={false} header={header}>
            <Helmet>
                <title>
                    Bay Equity News - Home Loan Resources | Bay Equity Home
                    Loans
                </title>
                <meta
                    name="description"
                    content="We’re committed to providing clients with full access to our home loan expertise. Check out our range of blog posts, and if you still have questions, give us a call"
                />
            </Helmet>
            <BayEquityNews {...props} />
        </Layout>
    );
};
export default BayEquityNewsPage;

export const pageQuery = graphql`
    query BayEquityNewsQuery {
        corpBlogs: allBlog(
            limit: 20
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" } }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                }
            }
        }
        featBlogs: allBlog(
            limit: 20
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, feature: { eq: true } }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                }
            }
        }
    }
`;
