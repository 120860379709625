import React, { Component } from "react";

import Accent from "../../../Accent";

import classnames from "classnames";
import styles from "./styles.module.less";
import { words } from "lodash";

import BEbug from "../../../../images/bayequity_circle_mark.png";
import BlogFallBack1 from "../../../../images/BlogFallBack1.jpg";
import BlogFallBack2 from "../../../../images/BlogFallBack2.jpg";
import BlogFallBack3 from "../../../../images/BlogFallBack3.jpg";

class FeaturedBlogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: this.props.blogs,
            active: 0,
            direction: "",
            wide: false,
        };
        this.rightClick = this.moveRight.bind(this);
        this.leftClick = this.moveLeft.bind(this);
        this.setActive = this.setActive.bind(this);
    }

    renderSlides(blog, index) {
        let position;
        const { active } = this.state;

        if (active === index) {
            position = `${styles.active}`;
        } else if (active === index + 1) {
            position = `${styles.prev}`;
        } else if (active === index - 1) {
            position = `${styles.next}`;
        } else if (active > index) {
            position = `${styles.left}`;
        } else if (active < index) {
            position = `${styles.right}`;
        }
        //get date
        let d = new Date(blog.date.toString());
        let date = d.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
        });

        //parse image & html
        let img = blog.image;
        let html = blog.html;
        // if there is an image url, Netlify puts it in the html so we gotta get it out.
        if (!img) {
            //random int 1 to 3
            let n = Math.floor(Math.random() * 3);
            //fallback for feature img
            const fallBacks = [BlogFallBack1, BlogFallBack2, BlogFallBack3];
            img = fallBacks[n];
        }
        img = img.split(" ")[0];
        html = words(html, /[^, ]+/g)
            .slice(0, 55)
            .join(" ")
            .concat("...");

        //parse owner image
        let authorImg = "";
        let author = "";
        let useAD = true;
        let authorIsBe = false;

        if (blog.author && blog.author !== "bayequity") {
            authorImg = blog.photo;
            author = blog.author;
        } else {
            //fallback for owner img
            author = "bayequity";
            authorImg = BEbug;
            useAD = false;
            authorIsBe = true;
        }

        if (authorImg.includes("bayequity_circle_mark")) authorIsBe = true;

        return (
            <div
                key={index}
                className={
                    position
                        ? `${styles.SliderItem} ${position}`
                        : styles.SliderItem
                }>
                <a
                    href={blog.slug}
                    className={styles.photoURL}
                    style={{ backgroundImage: `url("${img}")` }}>
                    {(useAD && (
                        <img
                            data-blink-src={authorImg}
                            alt={author}
                            className={authorIsBe ? styles.icon : styles.photo}
                        />
                    )) || (
                        <img
                            src={authorImg}
                            alt={author}
                            className={authorIsBe ? styles.icon : styles.photo}
                        />
                    )}
                </a>

                <div className={styles.content}>
                    <div className={styles.date}>{date}</div>
                    <a href={blog.slug} className={styles.blogTitle}>
                        {blog.title}
                    </a>

                    <Accent align="left" />

                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            </div>
        );
    }

    renderSliderNav() {
        const blogs = this.state.blogs;
        const active = this.state.active;

        return blogs.map((blog, index) => (
            <button
                onClick={() => this.setActive(index)}
                key={index}
                className={classnames({ [styles.active]: active === index })}>
                <li
                    className={classnames(styles.LessonSlide__navDot, {
                        [styles.active]: active === index,
                    })}
                />
            </button>
        ));
    }

    setActive(selected) {
        const direction = selected > this.state.active ? "right" : "left";

        this.setState({
            active: selected,
            direction: direction,
        });
    }

    moveLeft() {
        const newActive = this.state.active - 1;

        if (this.state.active + 1 === 1) {
            return;
        }

        this.setState({
            active: newActive < 0 ? this.state.blogs.length - 1 : newActive,
            direction: "left",
        });
    }

    moveRight() {
        const newActive = this.state.active;

        if (this.state.active + 1 === this.props.blogs.length) {
            return;
        }

        this.setState({
            active: (newActive + 1) % this.props.blogs.length,
            direction: "right",
        });
    }

    render() {
        const { blogs } = this.props;

        if (!blogs || blogs.length === 0) {
            return null;
        }
        return (
            <section className={styles.container}>
                <h1 className={styles.title}>
                    Features
                    <Accent align="left" />
                </h1>

                <div className={styles.inner}>
                    <div className={styles.SliderItem__container}>
                        <div
                            className={`${styles.LessonSlider__container} ${styles.noselect}`}>
                            {this.state.active + 1 !== 1 && (
                                <button
                                    className={`${styles.LessonSlider__button} ${styles.LessonSlider__button__prev}`}
                                    onClick={this.leftClick}
                                />
                            )}

                            {this.state.blogs.map((blog, index) =>
                                this.renderSlides(blog, index),
                            )}

                            {this.state.active + 1 !==
                                this.state.blogs.length && (
                                <div
                                    className={`${styles.LessonSlider__button} ${styles.LessonSlider__button__next}`}
                                    onClick={this.rightClick}
                                />
                            )}
                        </div>

                        <ul
                            className={`${styles.LessonSlide__nav} ${styles.noselect}`}>
                            {this.renderSliderNav()}
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}

export default FeaturedBlogs;
