import React, { Component } from "react";

import IntroHeader from "./../IntroHeader";
import FeaturedBlogs from "./components/FeaturedBlogs";
import WhatsNew from "./components/WhatsNew";
import WhatInterestsYou from "./components/WhatInterestsYou";

import BEbug from "./../../images/bayequity_circle_mark.png";

class BayEquityNews extends Component {
    render() {
        const { corporateBlogs } = this.props;
        let featuredBlogs = corporateBlogs
            .filter(blog => blog.feature === true)
            .slice(0, 6);

        let introHeader = "Bay Equity News";
        let par =
            " We’re committed to connecting with our clients. Whether it’s a conversation in person, a quick call or text during your lunch break or by sharing valuable news you can browse on your own time, we want you to have full access to our expertise.";
        let alt =
            "The Bay Equity Home Loans Bug - BE Bug Icon | Bay Equity Home Loans";

        return (
            <section>
                <IntroHeader
                    header={introHeader}
                    par={par}
                    img={BEbug}
                    alt={alt}
                />
                <FeaturedBlogs blogs={featuredBlogs} />
                <WhatsNew blogs={corporateBlogs} />
                <WhatInterestsYou />
            </section>
        );
    }
}

export default BayEquityNews;
