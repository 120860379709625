import React, { Component } from "react";

import Accent from "../../../Accent";

import styles from "./styles.module.less";
import AllAboutBE from "./AllAboutBE.png";
import FirstTime from "./FirstTimeHomebuyer.png";
import CurrentHome from "./CurrentHomeowner.png";
import DIY from "./DIYProjects.png";
import Resources from "./Resources.png";
import BeyondBE from "./BeyondBE.png";

class WhatInterestsYou extends Component {
    renderLinks(tag, index) {
        return (
            <div key={index} className={styles.linkBlock}>
                <a
                    href={`/bay-equity-news/${tag.name
                        .trim()
                        .replace(/ /g, "-")
                        .toLowerCase()}`}>
                    <div className={styles.tagName}>{tag.name}</div>
                    <img src={tag.img} alt={tag.name} />
                </a>
            </div>
        );
    }
    render() {
        let tags = [
            {
                name: "All About BE",
                img: AllAboutBE,
            },
            {
                name: "First-Time Homebuyer",
                img: FirstTime,
            },
            {
                name: "Current Homeowner",
                img: CurrentHome,
            },
            {
                name: "DIY Projects",
                img: DIY,
            },
            {
                name: "Resources",
                img: Resources,
            },
            {
                name: "Beyond BE",
                img: BeyondBE,
            },
        ];

        if (this.props.tags) {
            tags = this.props.tags;
        }
        return (
            <section className={styles.container}>
                <h1 className={styles.title}>
                    What interests you?
                    <Accent align="left" />
                </h1>

                <div className={styles.linkContainer}>
                    {tags.map((tag, i) => this.renderLinks(tag, i))}
                </div>
            </section>
        );
    }
}
export default WhatInterestsYou;
