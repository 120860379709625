import React, { Component } from "react";

import Accent from "../../../Accent";

import LinkButton from "./../../../LinkButton";
import BEbug from "../../../../images/bayequity_circle_mark.png";
import BlogFallBack1 from "../../../../images/BlogFallBack1.jpg";
import BlogFallBack2 from "../../../../images/BlogFallBack2.jpg";
import BlogFallBack3 from "../../../../images/BlogFallBack3.jpg";

import styles from "./styles.module.less";
class WhatsNew extends Component {
    renderBlogs = (blog, index) => {
        //get date
        let d = new Date(blog.date.toString());
        let date = d.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
        });

        //parse image & html
        let img = "";

        // if there is an image url like the cms shows, use that
        if (blog.image) {
            img = blog.image;
        } else {
            //random int 1 to 3
            let n = Math.floor(Math.random() * 3);
            //fallback for feature img
            const fallBacks = [BlogFallBack1, BlogFallBack2, BlogFallBack3];
            img = fallBacks[n];
        }

        //parse owner image
        let authorImg = "";
        let author = "";
        let useAD = true;
        let authorIsBe = false;

        if (blog.author && blog.author !== "bayequity") {
            authorImg = blog.photo;
            author = blog.author;
        } else {
            //fallback for owner img
            author = "bayequity";
            authorImg = BEbug;
            useAD = false;
            authorIsBe = true;
        }

        if (authorImg.includes("bayequity_circle_mark")) authorIsBe = true;

        let hashtag = [];
        //hashtag
        if (blog.categories) {
            for (let j = 0; j < blog.categories.length; j++) {
                let tag = "#" + blog.categories[j].replace(/\s/g, "");
                hashtag.push(` ${tag}`);
            }
        }
        return (
            <div className={styles.blogItem} key={blog.id}>
                <a href={blog.slug}>
                    <div
                        className={styles.photoURL}
                        style={{ backgroundImage: `url("${img}")` }}>
                        <span className={styles.hashtag}>{hashtag}</span>
                        {useAD ? (
                            <img
                                data-blink-src={authorImg}
                                alt={author}
                                className={
                                    authorIsBe ? styles.icon : styles.photo
                                }
                            />
                        ) : (
                            <img
                                src={authorImg}
                                alt={author}
                                className={
                                    authorIsBe ? styles.icon : styles.photo
                                }
                            />
                        )}
                    </div>
                    <div className={styles.content}>
                        <div className={styles.date}>{date}</div>
                        <div className={styles.blogTitle}>{blog.title}</div>
                    </div>
                </a>
            </div>
        );
    };
    render() {
        const { blogs } = this.props;
        let items = blogs;
        if (!blogs || blogs.length === 0) {
            return null;
        }
        return (
            <section>
                <div className={styles.blogContainer}>
                    <div className={styles.inner}>
                        <h1 className={styles.title}>
                            What&#8217;s new
                            <Accent align="left" />
                        </h1>

                        <div className={styles.innerContainer}>
                            <div className={styles.blogPanelLarge}>
                                {this.renderBlogs(items[0])}
                            </div>
                            <div className={styles.blogPanel}>
                                {items.slice(1, 5).map(this.renderBlogs)}
                            </div>
                        </div>

                        <div className={styles.ViewAll}>
                            <LinkButton
                                href="/bay-equity-news/archive"
                                label="View All"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WhatsNew;
